import React from "react"
import Seo from "../components/seo"
import Contacts from "../components/contacts"
import { Link } from "gatsby"
import Container from "../components/shared/policies/termsContainer"
import Text from "../components/shared/policies/textContainer"
import Title from "../components/shared/policies/title"
import Subtitles from "../components/shared/policies/subtitles"
import Paragraphs from "../components/shared/policies/paragraphs"
import Table from "../components/shared/policies/table"
import NewFooter from "../components/shared/NewFooter"

function PrivacyPolicy() {
  function SendEmailTo() {
    if (typeof window !== "undefined") {
      window.open("mailto:ceo@pdfpro.com")
    }
  }
  return (
    <>
      <Container>
        <Seo title="Privacy Policy" />
        <Title>Privacy Policy – pdfpro.com</Title>
        <Text>
          <Paragraphs>
            <i>Effective date: October 6th, 2021</i>
          </Paragraphs>
          <Subtitles>GENERAL INFORMATION</Subtitles>
          <Paragraphs>
            PDF Pro Software Ltd., with registered office at 1383 W 8th Ave,
            Vancouver, BC V6H 3V9, Canada, Business Registration no.
            700468291BC0001 (hereinafter referred to as "<strong>we</strong>" or
            "<strong>us</strong>)", in its capacity of data controller regarding
            the processing of Personal Data, is committed to protecting and
            respecting the privacy of its users, customers and suppliers, even
            prospective (hereinafter singularly and collectively referred to as
            the "<strong>you</strong>" or "<strong>your</strong> "), pursuant to
            the applicable Canadian laws on data protection (hereinafter
            referred to as the "<strong>National Law</strong>") and, if you are
            citizen of a Country in the European Economic Area, also pursuant to
            the European Regulation no. 679/2016 (hereinafter referred to as the
            "<strong>GDPR</strong>") (hereinafter the National Law and the GDPR
            will be referred to as the "<strong>Applicable Law</strong>").
          </Paragraphs>
          <Paragraphs>
            This policy (hereinafter referred to as the "
            <strong>Privacy Policy</strong>") is aimed to inform you about our
            practices related to our collection and use of your Personal Data
            either through our website <Link to="/">pdfpro.com </Link>
            (hereinafter referred to as the "<strong>Site</strong>") or during
            the performance of any of our service (hereinafter collectively
            referred to as the "<strong>Services</strong>").
          </Paragraphs>
          <Paragraphs>
            "<strong>Personal Data</strong>" means any information relating to
            an identified or identifiable natural person; an identifiable
            natural person is one who can be identified, directly or indirectly,
            in particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier or to one
            or more factors specific to the physical, physiological, genetic,
            mental, economic, cultural or social identity of that natural
            person.
          </Paragraphs>
          <Paragraphs>
            We invite you to read this Privacy Policy carefully to understand
            our considerations and practices regarding the processing of your
            Personal Data.
          </Paragraphs>
          <Subtitles>PERSONAL DATA WE PROCESS</Subtitles>
          <Paragraphs>
            When you visit the Site or when we provide you with our Services, we
            may collect the following Personal Data:
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            1.1. Information you give us.
          </Subtitles>
          <Paragraphs>
            You may, through our Site, our Services or other contact channel
            (e.g., e-mail, etc.), voluntarily provide us Personal Data and/or
            information and documents containing Personal Data. In particular,
            such Personal Data may include your name, email, address, order
            number, financial data, and any other information you send through
            our customer support. We will process these data in accordance with
            the Applicable Law and on the assumption that they refer to you or
            to third parties who have authorized you to provide them pursuant to
            an appropriate legal basis which legitimize the processing at stake.
            In this case, you act as independent data controller, assuming all
            relevant obligations and responsibilities according to the
            Applicable Law. In this regard, you hence waive, in the full sense
            of the term, the right to all disputes, claims, claims for damages
            due to processing, etc., which may be submitted to us by the said
            third parties whose Personal Data have been processed through your
            use of the Site or the Services in breach of the Applicable Law.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            1.2. Browsing data.
          </Subtitles>
          <Paragraphs>
            Computer systems and software procedures used to operate the Site
            collect some Personal Data, the transmission of which is an integral
            part of internet communication protocols. This information is not
            collected to be associated with you but, by its very nature, it may
            allow you to be identified by processing and associating it with
            data held by third parties. Among collected Personal Data there are
            : (i) IP addresses or domain names of the devices used by you to
            connect to the Site; (ii) the URI (Uniform Resource Identifier) of
            requested resources; (iii) the time of the request, the method used
            to submit the request to the server; (iv) the size of the file
            received as a reply; (v) the numeric code indicating the status of
            the reply given by the server (successful, error, etc.); (vi) other
            parameters regarding your operating system and device environment.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            1.3. Cookies and similar technologies.
          </Subtitles>
          <Paragraphs>
            We may collect Personal Data using cookies. You can find further
            information on the use of cookie and similar technologies{" "}
            <Link to="/cookies">here</Link>.
          </Paragraphs>
          <Subtitles>PURPOSES AND LEGAL BASIS OF THE PROCESSING</Subtitles>
          <Subtitles size="medium" height="medium">
            2.1. Purposes.
          </Subtitles>
          <Paragraphs>
            Personal Data provided by you will be processed by us for the
            purposes and legal basis specified below
          </Paragraphs>
          <Table>
            <thead>
              <tr>
                <th>Purposes</th>
                <th>Legal basis</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  To carry out our obligations arising from any contracts
                  entered into between you and us and to provide you with the
                  information and Services that you requested from us (e.g.,
                  create and manage your account, provide our Services, process
                  payments, and respond to your inquiries, process the
                  subscription to our newsletter, etc.).
                </td>
                <td>
                  This processing is necessary for the performance of our mutual
                  contractual obligations and/or carried out with your consent.
                </td>
              </tr>
              <tr>
                <td>
                  To communicate with you to verify your account and for
                  informational and operational purposes (e.g., account
                  management, customer service, system maintenance), including
                  by periodically emailing you Services-related announcements.
                </td>
                <td>
                  This processing is necessary for the performance of our mutual
                  contractual obligations and/or carried out with your consent.
                </td>
              </tr>
              <tr>
                <td>
                  To give you access to our support and customer care Services
                  and to enable you to communicate with our team
                </td>
                <td>
                  This processing is necessary for the performance of our mutual
                  contractual obligations, carried out with your consent and/or
                  necessary for the establishment, exercise or defense of legal
                  claims.
                </td>
              </tr>
              <tr>
                <td>
                  To ensure compliance with any applicable laws, our Services'
                  terms and conditions, and our Privacy Policy.
                </td>
                <td>
                  This processing is necessary for the performance of our mutual
                  contractual obligations and/or for the establishment, exercise
                  or defense of legal claims.
                </td>
              </tr>
              <tr>
                <td>
                  To send information to authorized third-parties (e.g.,
                  customers, end-users, etc.).
                </td>
                <td>
                  This processing is necessary for the performance of our mutual
                  contractual obligations and/or carried out with your consent
                </td>
              </tr>
              <tr>
                <td>
                  To send marketing materials, as well as suggestions and
                  recommendations on our services that may be of interest to
                  you.
                </td>
                <td>This processing is based on your consent.</td>
              </tr>
              <tr>
                <td>
                  To carry on statistical research / analysis of aggregated or
                  anonymous data, without identifying you, as well as to measure
                  and evaluate the operation of our Site, its traffic and
                  usability.
                </td>
                <td>
                  This processing does not involve the processing of Personal
                  Data.
                </td>
              </tr>
              <tr>
                <td>
                  To the fulfill a legal obligation to which we are subject or
                  to ascertain, exercise or defend a right in Court or whenever
                  an authority exercises its jurisdiction.
                </td>
                <td>
                  This processing is necessary for the establishment, exercise
                  or defense of legal claims.
                </td>
              </tr>
            </tbody>
          </Table>
          <Subtitles size="medium" height="medium">
            2.2. Voluntary nature of the processing.
          </Subtitles>
          <Paragraphs>
            Providing your Personal Data for the above-mentioned purposes is
            voluntary and not mandatory. However, any refusal to provide any of
            such data may not allow us to establish and/or continue a
            contractual relationship with you, or to fulfill your requests, or
            to comply with legal obligations to which we are subject.
          </Paragraphs>
          <Subtitles>
            WHAT IS DATA RETENTION PERIOD AND WHAT SECURITY MEASURES HAVE BEEN
            TAKEN FOR YOUR PERSONAL DATA SAFEGUARD
          </Subtitles>
          <Subtitles size="medium" height="medium">
            3.1. Data retention.
          </Subtitles>
          <Paragraphs>
            Personal Data collected by us will be processed for the time
            strictly necessary to achieve the purposes referred to in above. In
            particular:
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            3.1.a.
          </Subtitles>
          <Paragraphs>
            Personal Data collected through the provision of Services will be
            deleted (or anonymized for statistical purposes) after _2_ (_two_)
            years from the termination of the Services or, if earlier, after
            your cancellation request;
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            3.1.b.
          </Subtitles>
          <Paragraphs>
            Personal Data needed for the provision of our newsletter service
            will be processed until you decide to unsubscribe;
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            3.1.c.
          </Subtitles>
          <Paragraphs>
            Personal Data whose retention is mandatory under the applicable laws
            (e.g., tax laws, bookkeeping, etc.) will be retained for a period of
            time necessary or permitted to comply with such laws.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            3.2. Security measures.
          </Subtitles>
          <Paragraphs>
            We warrant to maintains and continue to maintain appropriate and
            sufficient technical and organizational security measures to protect
            your Personal Data against accidental or unlawful destruction or
            accidental loss, damage, alteration, unauthorized disclosure or
            access, in particular where the Processing involves the transmission
            of data over a network, and against all other unlawful forms of
            processing. Please be aware that no security measures are perfect or
            impenetrable. So, while we strive to protect your data, we cannot
            guarantee that unauthorized access, hacking, data loss or a data
            breach will never occur. Notwithstanding the preceding, we operate
            with the aim of mitigating the risks associated with processing your
            Personal Data through several measures, including:
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            3.2.a. Data Minimization.
          </Subtitles>
          <Paragraphs>
            We only ever obtain, retain, process and share Personal Data that is
            essential to carry out our services and legal obligations: only that
            which is relevant and necessary is collected. In particular, by way
            of example, our electronic collections (i.e., via the Site, etc.),
            have only fields that are relevant to the purpose of collection and
            subsequent processing, while the physical collection (i.e.,
            face-to-face contacts, phone calls, etc.) is supported using scripts
            and internal forms using predefined fields.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            3.2.b. Pseudonymization.
          </Subtitles>
          <Paragraphs>
            Whenever possible, we utilize pseudonymization to record and store
            Personal Data in a way that ensures that such data can no longer be
            attributed to a specific data subject without the use of separate
            additional information (i.e., personal identifiers) which are
            protected with encryption, partitioning and other technical and
            operational measures of risk reduction and data protection.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            3.2.c. Encryption.
          </Subtitles>
          <Paragraphs>
            Although we use encryption (i.e., using a secret key to make
            Personal Data indecipherable unless decryption of the dataset is
            carried out using such assigned key) as a form of pseudonymization,
            we also utilize it as a secondary risk prevention measure for
            securing the Personal Data that we process. In particular, we
            utilize encryption via secret key for transferring Personal Data
            and/or special category of information to any external party and
            provide the secret key in a separate format.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            3.2.d. Access restriction.
          </Subtitles>
          <Paragraphs>
            We use company-wide restriction methods for restricting access into
            the foundation of our processes, systems and structure, in order to
            ensure that only those with authorization and/or a relevant purpose,
            have access to Personal Data. Special category data is restricted at
            all levels and can only be accessed by our authorized personnel.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            3.2.e. No hard copy data.
          </Subtitles>
          <Paragraphs>
            We never store any Personal Data in hard copy format.
          </Paragraphs>
          <Subtitles>WHO ARE THE RECIPIENTS OF YOUR PERSONAL DATA</Subtitles>
          <Subtitles size="medium" height="medium">
            4.1. Third-party service providers or consultants.
          </Subtitles>
          <Paragraphs>
            We engage certain trusted third parties to perform functions and
            provide services to us, including hosting and maintenance, error
            monitoring, debugging, performance monitoring, billing, customer
            relationship, database storage and management, and direct marketing
            campaigns. We may share your Personal Information with these third
            parties, but only to the extent necessary to perform these functions
            and provide such services. We also require these third parties to
            maintain the privacy and security of your Personal Data they process
            on our behalf. In particular, without limiting the foregoing, our
            principal third-party service providers are:{" "}
            <a href="https://fastspring.com/">Fastspring</a>, which is our
            e-commerce provider),
            <a href="https://mailchimp.com/">Mailchimp</a> , which is our mail
            marketing service provider),
            <a href="https://www.zendesk.com/">Zendesk</a> , which provide us
            with a customer support ticketing system), and{" "}
            <a href="https://licensespring.com/">LicenseSpring</a>, which is our
            license information service provider).
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            4.2. Other Third Parties.
          </Subtitles>
          <Paragraphs>
            We may share Personal Data with our headquarters and affiliates, and
            business partners to whom it is reasonably necessary or desirable
            for us to disclose your data for the purposes described in this
            Privacy Policy. In particular, our marketing team is located in
            Canada, our customer support office is located in the Philippines,
            and our financial department is located in Switzerland.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            4.3. Third parties required by laws or authorities.
          </Subtitles>
          <Paragraphs>
            We may disclose your data to a third party if (i) we believe that
            disclosure is reasonably necessary to comply with any applicable
            law, regulation, legal process or governmental request (including to
            meet national security or law enforcement requirements), (ii) to
            enforce our agreements and policies, (iii) to protect the security
            or integrity of our services and products, (iv) to protect
            ourselves, our other customers, or the public from harm or illegal
            activities, or (v) to respond to an emergency which we believe in
            good faith requires us to disclose data to assist in preventing a
            death or serious bodily injury. If we are required by law to
            disclose any of your Personal Data, then we will use reasonable
            efforts to provide you with notice of that disclosure requirement,
            unless we are prohibited from doing so by statute, subpoena or court
            or administrative order. Further, we object to requests that we do
            not believe were issued properly.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            4.4. Aggregated or de-identified data.
          </Subtitles>
          <Paragraphs>
            We may also make certain non-Personal Data (i.e., data which has
            been de-identified or aggregated in a way that does not directly
            identify you) available to third parties for various purposes,
            including for business or marketing purposes or to assist third
            parties in understanding our users' interest, habits, and usage
            patterns for certain programs, content, services, advertisements,
            promotions, and functionality available through the Services.
          </Paragraphs>
          <Subtitles>WHERE YOUR PERSONAL DATA MAY BE TRANSFERRED</Subtitles>
          <Paragraphs>
            We are based in Canada, but we are a decentralized business.
            Therefore, Personal Data that we collect may be transferred to, and
            stored at, any of our affiliates, partners or service providers
            mentioned in previous Sect. 4, which may be either inside or outside
            the European Economic Area. By submitting your Personal Data, you
            agree to such transfers. Your Personal Data may be transferred to
            countries that do not have the same data protection laws as the
            country in which you initially provided the information, but we
            ensure that when we transfer or disclose your Personal Data to such
            other countries, we will protect that information as described in
            this Privacy Policy.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            6.1. Right of access.
          </Subtitles>
          <Paragraphs>
            You are always entitled to receive confirmation as to whether or not
            your Personal Data are being processed and, where that is the case,
            access and receive copy of such Personal Data in an intelligible
            form. Furthermore, you are also entitled to receive information
            concerning: the purposes of the processing; the categories of
            Personal Data concerned; the recipients (or categories thereof) to
            whom the Personal Data have been or will be disclosed; where
            possible, the envisaged period for which the Personal Data will be
            stored, or, if not possible, the criteria used to determine that
            period; the existence of the right to request from us rectification
            or erasure of personal data or restriction of processing of your
            Personal Data or to object to such processing; the right to lodge a
            complaint with a supervisory authority; the source of the Personal
            Data; the existence of automated decision-making; where Personal
            Data are transferred to a third country or to an international
            organization, the appropriate safeguards relating to the transfer.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            6.2. Right to withdraw consent.
          </Subtitles>
          <Paragraphs>
            You are always entitled to withdraw, at any time, your consent to
            the processing of your Personal Data, both on legitimate grounds
            (even though they are relevant to the purpose of the collection) and
            if the processing is carried out for direct marketing purpose. The
            preceding will not affect the lawfulness of processing based on
            consent before the withdrawal.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            6.3. Right to rectification, erasure and restriction.
          </Subtitles>
          <Paragraphs>
            You are always entitled to obtain from us, without undue delay: the
            rectification or integration of your Personal Data that are
            inaccurate or incomplete; the erasure of your Personal Data that
            have been processed unlawfully or whose retention is unnecessary for
            the Purposes; the restriction of processing, in case you challenge
            either the accuracy of your Personal data or the lawfulness of the
            processing, or in case we no longer need the Personal Data for the
            Purposes, but they are required by you for the establishment,
            exercise or defense of a legal claim.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            6.4. Right to data portability.
          </Subtitles>
          <Paragraphs>
            You have the right to receive your Personal Data in a structured,
            commonly used and machine-readable format, as well as the right to
            transmit those data to another controller without hindrance from us,
            where technically feasible.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            6.5. Right to lodge a complaint before an European supervisory
            authority.
          </Subtitles>
          <Paragraphs>
            In case you are an European citizen, you have the right to lodge a
            complaint with the Supervisory Authority located in your place of
            residence.
          </Paragraphs>
          <Subtitles size="medium" height="medium">
            6.6. Contacts.
          </Subtitles>
          <Paragraphs>
            Requests to exercise the rights above must be sent to PDF Pro
            Software Ltd., 1383 W 8th Ave, Vancouver, BC V6H 3V9, Canada, or by
            e-mail to{" "}
            <button onClick={SendEmailTo}>
              <u>contact@pdfpro.com</u>
            </button>
            . Any access request is always completed within one month; however,
            where the retrieval or provision of information is particularly
            complex or is subject to a valid delay, the period may be extended
            by two further months. If this is the case, we will write to the
            individual within one month and keep him/her informed of the delay
            and the reasons thereof.
          </Paragraphs>
          <Subtitles>AMENDMENTS TO THIS POLICY</Subtitles>
          <Paragraphs>
            This Privacy Policy came into force on the date specified above. We
            reserve the right to amend or to update its content, whether in
            whole or in part, also following changes in the legal and regulatory
            obligations regarding data protection. We will inform you on such
            amendments and updates through their publication on the Site as soon
            as they are adopted, and they will be binding from the moment of
            their publication. Therefore, we invite you to visit this section of
            the Site regularly, in order to be aware of the most recent and
            updated version thereof, so that you are always updated on the
            processing activities that we carry out.
          </Paragraphs>
        </Text>
      </Container>
      <NewFooter />
    </>
  )
}

export default PrivacyPolicy
